<template>
	<div
		:class="['panel', 'teaser',
			isHeaderIndent? 'mt-0 pt-1' : '',
			isFooterIndent? 'mb-0 pb-1' : '',
			block.appearance? block.appearance : '',
		]"
		:style="block.background_image_default.src ?
			'background-image:url(' + block.background_image_default.src + ')' : ''"
	>
		<div class="container">
			<div v-if="isHeaderVisible" :class="'panel_header' + (isBrand ? ' sk' : '')">
				<div class="row">
					<div class="col">
						<h2 v-if="block.block_title === null">
							Презентация программы
						</h2>
						<h2 v-else>
							{{ block.block_title }}
						</h2>
					</div>
				</div>
			</div>
			<ul class="announce_list">
				<!-- <li v-for="(item, index) in block.items" :key="index" class="row">
					<div v-if="item.image.src" class="col-12 col-lg-4">
						<div class="announce_image_block video">
							<b-img-lazy :src="item.image.src" />
						</div>
					</div>
					<div :class="item.image.src ? 'col-12 col-lg-8' : 'col-12 col-lg-12'">
						<div class="announce_content_block">
							<div class="announce_content_middle">
								<p class="title">
									{{ item.title }}
								</p>
								<div class="description" v-html="item.content" />
							</div>
							<div v-if="item.type==='video'" class="announce_content_footer">
								<button class="btn btn-link more_link video" @click="showModal(item.embed_url);">
									Смотреть
								</button>
							</div>
						</div>
					</div>
				</li> -->

				<li v-for="(item, index) in block.items" :key="index" class="row">
					<div v-if="item.type==='video'" class="col-12 col-lg-5">
						<div v-b-visible.once="onVideoTeaserVisibility" class="teaser_video_block theme-inverse">
							<b-embed
								v-if="showEmbed"
								:src="item.embed_url"
								type="iframe"
								aspect="16by9"
								allowfullscreen
							/>
						</div>
					</div>
					<div v-else-if="item.image.src" class="col-12 col-lg-5">
						<div class="teaser_image_block theme-inverse">
							<b-img-lazy :src="item.image.src" />
						</div>
					</div>

					<div :class="(item.type==='video' || item.image.src) ? 'col-12 col-lg-7' : 'col-12 col-lg-12'">
						<div class="teaser_content_block">
							<div class="teaser_content_middle">
								<p v-if="item.title" class="title">
									{{ item.title }}
								</p>
								<div class="description" v-html="item.content" />
							</div>
						</div>
					</div>
				</li>
			</ul>
			<div v-if="isFooterVisible" class="panel_footer">
				<dynamic-button
					:button="block.button"
					:block-id="block.id"
					:scenario="block.scenario"
				/>
			</div>
		</div>
		<!-- <b-modal id="bv-modal-video" hide-footer>
			<div class="d-block text-center">
				<b-embed
					v-if="modalVideoSrc"
					:src="modalVideoSrc + '?rel=0&enablejsapi=1'"
					type="iframe"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					frameborder="0"
					allowfullscreen
				/>
			</div>
		</b-modal> -->
	</div>
</template>

<script>
import panel from '~/mixins/panel';

export default {
	name: 'ProgTeaser',
	mixins: [panel],
	data() {
		return {
			showEmbed: false,
		};
	},
	methods: {
		// showModal(src) {
		// 	this.$bvModal.show('bv-modal-video');
		// 	this.modalVideoSrc = src;
		// },
		onVideoTeaserVisibility(visible) {
			if (visible) {
				this.showEmbed = true;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	// @import "~/assets/styles/components/home/panel-announces";
	@import "~/assets/styles/components/home/panel-teaser";
</style>
